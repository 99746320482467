* {
  font-family: vollkorn, serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

.navbar {
  padding: 0px !important;
}

$heading-color: #d8392b;
// #54a0ff;
$base-color: #516856;

.theme-color {
  color: #ddd !important;

  &:hover {
    color: #d8392b !important;
  }
}

@media (min-width: 541px) {
  .main__container_gorkhali {
    width: 80%;
    margin: auto;
  }
}

// @media (max-width: 1000px) {
//     .main__container_gorkhali {

//         .kathmanduImage {
//             height: 50vh !important;
//         }
//     }
// }

@media (max-width: 700px) {
  .main__container_gorkhali {
    .kathmanduImage {
      height: 20vh !important;
    }
  }
}

@media (max-width: 1200px) {
  .main__container_gorkhali {
    .image_container {
      width: 100% !important;
    }

    // .kathmanduImage {
    //     height: 22vh !important;
    // }
  }
}

.michLogo {
  width: 160px;
  height: 160px;
}
.michLogo-24 {
  width: 140px;
  height: 140px;
}

@media (max-width: 540px) {
  .main__container_gorkhali {
    width: 100%;
  }
}

.main__container_gorkhali {
  .image_container {
    margin: auto !important;
    width: 60%;
  }

  .kathmanduImage {
    width: 100%;
    margin: auto;
    height: 27vh;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .storyline {
    // margin-top: 50px;
    margin-bottom: 50px;
  }
}

.w85 {
  width: 85% !important;
}

.mainContainer {
  // margin-top: 2rem !important;
  position: relative;
  z-index: 100;
}

.mainContainer__content {
  margin-top: 100px !important;
}

.mainContainer__content__title {
  h1 {
    color: $heading-color;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 4px;
    text-align: center;
    text-transform: uppercase;
    padding: 9px 0px 0px;
  }

  h2 {
    color: $base-color;
    font-family: vollkorn, serif;
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: 4px;
    line-height: 32px;
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .mainContainer__content__title {
    h1 {
      font-size: 1.2rem;
    }

    h2 {
      font-size: 1rem;
    }
  }
}

.mainContainer__content__description {
  // margin: 0px 50px;

  p {
    color: $base-color;
    font-family: vollkorn, serif;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 27px;
    text-align: center;
  }

  h4 {
    padding-top: 20px;
    text-align: center;
    color: $heading-color;
    font-family: vollkorn, serif;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 4px;
    line-height: 32px;
  }

  .para5 {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 27px;
    text-align: center;
    color: $heading-color;
  }

  .socialSvgs {
    width: 50% !important;
    margin: auto;
  }
}

@media screen and (max-width: 820px) {
  .socialSvgs {
    width: auto !important;
  }
}

@media screen and (max-width: 480px) {
  .mainContainer__content__description {
    p {
      font-size: 1rem;
    }

    .para5 {
      font-size: 1.1rem;
      line-height: 24px;
    }

    .socialSvgs {
      width: 100% !important;
    }

    .graph_col {
      margin: 5px;
    }
  }
}

.content_with_card {
  p {
    color: #696969;
    font-family: vollkorn, serif;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 27px;
    font-style: italic;
  }

  .card_title_overlay {
    position: absolute;
    bottom: 6px;
    left: 70px;
    font-style: italic;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
  }

  .card_content {
    width: 50%;
    // border:1px solid red
  }

  .card_img {
    position: relative;
    margin-left: 20px;
    // border:1px solid red
    // width

    // img{
    //     width: 100%;
    // }
  }

  .my__card {
    display: flex;
    justify-content: center;
    align-items: normal;
    flex-direction: row;
  }
}

@media screen and (max-width: 756px) {
  .content_with_card {
    .card_content {
      width: 100%;
    }

    .my__card {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
}

.bgImage {
  width: 100%;
  height: 45vh;
  background-attachment: fixed;
  background-image: url("../assets/background1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 30px;
  opacity: 1;
  background-color: #727272;
  z-index: 77;
  background-size: cover;
  background-attachment: fixed;
}

@media screen and (max-width: 480px) {
  .bgImage {
    height: 20vh;
    background-attachment: scroll;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}

.menu_section {
  margin-top: 5vh !important;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  span {
    text-decoration: underline;
    color: $heading-color;
    font-size: 18px;
    line-height: 31px;
    font-family: vollkorn, serif;
    font-weight: 400;
    letter-spacing: 1px;
    cursor: pointer;
  }

  p {
    color: $base-color;
    text-align: center;
  }
}

.menu_dishes_content {
  img {
    width: 100%;
    height: 22vh;
  }
}

@media screen and (max-width: 480px) {
  .menu_dishes_content {
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.bgImage2 {
  width: 100%;
  height: 45vh;
  background-attachment: fixed;
  background-image: url("https://images.unsplash.com/photo-1493770348161-369560ae357d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fGZvb2R8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60");
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 1;
  background-color: #727272;
  z-index: 77;
  background-size: cover;
  margin-bottom: 62px;
}

@media screen and (max-width: 480px) {
  .bgImage2 {
    height: 20vh;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}

.mainContainer_sharedDining {
  margin-top: 120px !important;

  .shared_para {
    margin-top: 30px;
    margin-bottom: 30px;

    p {
      color: $base-color;
      font-family: vollkorn, serif;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 1px;
      line-height: 27px;
      text-align: center;
    }
  }
}

.mainContainer_memories {
  .memoral_image {
    padding: 20px;
    width: 50%;
    margin: auto;

    img {
      width: 100%;
    }
  }

  .memoral_title {
    p {
      color: #696969;
      font-family: vollkorn, serif;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 1px;
      line-height: 27px;
      font-style: italic;
      text-align: center;
    }
  }
}

@media screen and (max-width: 480px) {
  .memoral_image {
    padding: 20px;
    width: 100% !important;
  }
}

@media screen and (max-width: 756px) {
  .mainContainer_memories {
    .titleImgDiv img {
      width: 100%;
    }
  }

  .memoral_image {
    width: 80% !important;
  }
}

.mainContainer_join {
  .memo_para {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    p {
      color: $base-color;
      font-family: vollkorn, serif;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 1px;
      line-height: 27px;
    }
  }
}

.bgImage3 {
  width: 100%;
  height: 45vh;
  background-attachment: fixed;
  background-image: url("https://images.unsplash.com/photo-1476224203421-9ac39bcb3327?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGZvb2R8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60");
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 1;
  background-color: #727272;
  z-index: 77;
  background-size: cover;
  margin-bottom: 62px;
}

@media screen and (max-width: 480px) {
  .bgImage3 {
    height: 20vh;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .memo_para {
    p {
      font-size: 1rem !important;
    }
  }
}

.mainContainer_reservation {
  .reservation_para {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    p {
      color: $base-color;
      font-family: vollkorn, serif;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 1px;
      line-height: 27px;
    }

    h4 {
      padding-top: 20px;
      text-align: center;
      color: $heading-color;
      font-family: vollkorn, serif;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 4px;
      line-height: 32px;
    }

    h3 {
      padding-top: 20px;
      text-align: center;
      color: $heading-color;
      font-family: vollkorn, serif;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 4px;
      line-height: 32px;
    }
  }
}

// ====header=====
.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.headerBar {
  h4 {
    color: $heading-color;
  }
}

.m_left {
  margin-left: 20% !important;
}

@media screen and (max-width: 767px) {
  .mobileView {
    width: 100%;
    display: block !important;
  }

  .mobile-d-none {
    display: none !important;
  }
}

@media screen and (max-width: 900px) {
  .mobile-d-none {
    margin-left: 30px !important;
  }
}

.main_navbar {
  // background-color: #fafbfc !important;
  background: #2b2d2d !important;
}

@media screen and (max-width: 1100px) {
  .m_left {
    margin-left: 8% !important;
  }
}

@media screen and (max-width: 990px) {
  .m_left {
    margin-left: 1% !important;
  }
}

textarea:focus,
textarea,
textarea.form-control:focus,
textarea.form-control,
// input.form-control:focus,
// input.form-control,
button.btn:focus,
// button.btn:active,
input[type=text]:focus,
input[type=text],
input[type=password]:focus,
input[type=password],
input[type=email]:focus,
input[type=email],
input[type=number]:focus,
[type=text].form-control:focus,
[type=text].form-control,
[type=password].form-control:focus,
[type=password].form-control,
[type=email].form-control:focus,
[type=email].form-control,
[type=checkbox].form-check-input,
[type=checkbox].form-check-input:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus,
[contenteditable].form-control {
  box-shadow: none !important;
  outline: none !important;
}

// .form-check-input:focus ~ .form-check-input::before {
//     box-shadow:none !important;
//     outline: none !important;
// }

.logoutBtn {
  background-color: #2b2d2d !important;
  width: 120px;
  position: relative;
  left: -30px;
  text-align: end;
  color: $heading-color !important;
  border: none !important;
  padding: 10px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 1px !important;
  text-transform: uppercase !important;
}

.email_handle_form {
  .FormLabel {
    color: $base-color !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    letter-spacing: 1px !important;
    font-family: vollkorn, serif !important;
  }

  input {
    color: #696969;
    font-weight: 400 !important;
  }
}

.admin_dashboard {
  color: $heading-color !important;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 4px;
  text-align: center;
  text-transform: uppercase;
  padding: 9px 0px 0px;
}

.admin_dashboard_content {
  h1 {
    color: $heading-color;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 4px;
    text-align: center;
    text-transform: uppercase;
    padding: 9px 0px 0px;
  }
}

@media screen and (max-width: 480px) {
  .admin_dashboard_content {
    h1 {
      font-size: 1rem;
    }
  }
}

// ====GORKHALI IMAGE COLLAGE=====

.gorkhali_image_container {
  width: 100%;
  overflow: hidden;
  .gorkhali_image {
    display: flex;
    justify-content: baseline;
    img {
      width: 50%;
      height: 50%;
    }
  }
}
