$heading-color: #eee;
// #54a0ff;
$base-color: #ddd;

$footer-text-color: #d8392b;

* {
	font-family: vollkorn, serif;
}

footer {
	padding: 2em 0 0 0
}

.footer_main_content {
	p {
		color: $base-color;
		font-size: 16px;
		line-height: 38px;
	}
}

.main_footer {
	background: #2B2D2D;
	box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
	font-size: 14px;

	a {
		text-decoration: none;
		color: $base-color;
		font-family: vollkorn, serif;
	}

	.footer-heading {
		font-size: 24px;
		text-transform: uppercase;
		letter-spacing: 2px;
		font-family: vollkorn, serif;
		font-weight: 400;
		margin-bottom: 30px;
		color: $heading-color;
	}

	.socialIcon_Container {
		.list-unstyled {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 120px;
			line-height: 0px;
			margin-bottom: 0px;
			padding: 2px;

			li {
				a {
					color: $heading-color;
					font-size: 22px;
				}

				:hover {
					color: $footer-text-color;
				}
			}
		}

	}

	.hoverEffect {
		a {
			&:hover {
				color: $footer-text-color;
				cursor: pointer;
				-webkit-transition: color .3s ease-out;
				-moz-transition: color .3s ease-out;
				-o-transition: color .3s ease-out;
				transition: color .3s ease-out;
			}
		}
	}

	@media screen and (max-width: 767px) {
		.list-unstyled {
			display: flex;
			justify-content: space-around;
		}
	}

	.subscribe-form {
		.form-group {
			position: relative;
			margin-bottom: 0;

			input {
				background: rgb(251, 251, 251) !important;
				border: none !important;
				outline: none !important;
				color: #2B2D2D !important;
				font-size: 16px;

				&::-webkit-input-placeholder {
					/* Chrome/Opera/Safari */
					color: rgba(0, 0, 0, .3) !important;
				}

				&::-moz-placeholder {
					/* Firefox 19+ */
					color: rgba(0, 0, 0, .3) !important;
				}

				&:-ms-input-placeholder {
					/* IE 10+ */
					color: rgba(0, 0, 0, .3) !important;
				}

				&:-moz-placeholder {
					/* Firefox 18- */
					color: rgba(0, 0, 0, .3) !important;
				}

				&:focus {
					outline: none !important;
					box-shadow: none;
				}
			}

			.submit {
				color: white !important;
				display: block;
				width: 135px;
				height: 52px;
				font-size: 16px;
				background: black !important;
				font-family: vollkorn, serif;
				border: none;

				&:hover,
				&:focus {
					text-decoration: none !important;
					outline: none !important;
					cursor: pointer;
					background-color: $footer-text-color  !important;
					-webkit-transition: background-color .3s ease-out;
					-moz-transition: background-color .3s ease-out;
					-o-transition: background-color .3s ease-out;
					transition: background-color .3s ease-out;
				}
			}
		}
	}
}


hr.underline {
	color: #DDDDDD;
	width: 100%;
	/* margin: 0; */
	margin-bottom: 20px;
	border-width: 2px;
}

.copyright {
	font-size: 18px;
	letter-spacing: 2px;
	color: #aaa !important;
}

.footerCredits {
	float: right;
	color: #aaa !important;
	font-size: 18px;
	letter-spacing: 2px;

	a {
		&:hover {
			color: $footer-text-color;
			cursor: pointer;
			-webkit-transition: color .3s ease-out;
			-moz-transition: color .3s ease-out;
			-o-transition: color .3s ease-out;
			transition: color .3s ease-out;
		}
	}
}

.copyright {
	a {
		&:hover {
			color: $footer-text-color;
			cursor: pointer;
			-webkit-transition: color .3s ease-out;
			-moz-transition: color .3s ease-out;
			-o-transition: color .3s ease-out;
			transition: color .3s ease-out;
		}
	}

}